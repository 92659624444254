<template>
  <div>
    <div class="d-flex pb-4">
      <div class="ticketTextHeading">Checklist (Things to do)</div>
      <div class="ticketTextLine"></div>
    </div>
    <div>
      <br />
      <v-row>
        <v-col cols="12">
          <div>
            <v-btn
              v-for="(item, i) in selectedBtnList"
              :key="i"
              :class="
                item.value === selectedBtn
                  ? 'activeBtnClass text-capitalize mr-2'
                  : 'notActiveBtnClass text-capitalize mr-2'
              "
              width="150px"
              @click="selectedBtn = item.value"
            >
              <span>{{ item.name }}</span>
            </v-btn>
            <!-- <v-btn
              :class="
                !selectedBtn
                  ? 'activeBtnClass text-capitalize ml-2'
                  : 'notActiveBtnClass text-capitalize ml-2'
              "
              @click="selectedBtn = false"
            >
              <span>Completed</span>
            </v-btn> -->
          </div>
        </v-col>
      </v-row>
      <br /><br />
    </div>
    <div>
      <v-data-table
        fixed-header
        hide-default-footer
        :headers="header"
        :items="items"
        :loading="loading"
        class="elevation-8"
        loading-text="Loading... Please wait"
        mobile-breakpoint="0"
      >
        <template v-slot:item="props">
          <tr v-if="props.item.checklist.name != 'Tickets'">
            <td
              class="text-center py-4"
              v-if="
                props.item.checklist.name == 'First Call' &&
                (props.item.user_type == 'admin' ||
                  props.item.user_type == 'Sales Representative' ||
                  props.item.user_type == 'Event Support Staff')
              "
            >
              <v-checkbox
                color="#38227A"
                v-model="props.item.is_completed"
                @change="updateStatus(props.item.is_completed, props.item.id)"
                disabled
              ></v-checkbox>
            </td>
            <td class="text-center py-4" v-else>
              <v-checkbox
                color="#38227A"
                v-model="props.item.is_completed"
                @change="updateStatus(props.item.is_completed, props.item.id)"
                v-if="
                  props.item.user_type == 'admin' ||
                  props.item.user_type == 'Sales Representative' ||
                  props.item.user_type == 'Event Support Staff'
                "
              ></v-checkbox>
            </td>
            <td class="text-center">
              <div style="color: #757575">
                <div>
                  {{ props.item.checklist.name }}
                </div>
                <div style="font-size: 12px">
                  {{ props.item.checklist.description }}
                </div>
              </div>
            </td>
            <td class="text-center">
              <div>
                {{ props.item.due_date }}
              </div>
              <div v-if="props.item.status_str">
                <v-chip small :class="`${props.item.status_str.toUpperCase()}`">
                  {{ props.item.status_str }}
                </v-chip>
              </div>
            </td>
            <td class="text-center">
              {{ props.item.completion_date_str }}
              <div v-if="props.item.assemblies != null">
                Assembly - {{ props.item.assemblies[0].date }}
              </div>
            </td>
            <td>
              <div
                v-if="props.item.action_dates.length > 0"
                style="margin-left: 30%"
              >
                <ul>
                  <li
                    v-for="actionDate in props.item.action_dates"
                    :key="actionDate.id"
                  >
                    {{ actionDate.action_date_str }}
                  </li>
                </ul>
              </div>
            </td>
            <td class="text-center">
              <v-btn
                text
                class="text-capitalize"
                style="border: 1px solid #4b18e5; border-radius: 10px"
                @click="takeAction(props.item.checklist.action_key)"
                v-if="
                  (props.item.user_type == 'admin' ||
                    props.item.user_type == 'Sales Representative' ||
                    props.item.user_type == 'Event Support Staff') &&
                  !(
                    props.item.checklist.action_name === 'Intro Email' &&
                    !introFlag
                  )
                "
              >
                <span
                  style="color: #4b18e5"
                  v-text="props.item.checklist.action_name"
                ></span>
              </v-btn>
            </td>
            <!--   <td class="text-center">
              <v-btn text class="text-capitalize">
                <span style="color: #4b18e5; border-bottom: 1px solid #4b18e5">
                  Download Resource
                </span>
              </v-btn>
            </td>-->
          </tr>
        </template>
      </v-data-table>
    </div>
    <hotel-info-modal></hotel-info-modal>
    <intro-email-modal></intro-email-modal>
  </div>
</template>
<script>
import Axios from "@/api/BaseAxios";
import { ROUTER_URL } from "@/constants/urls";
import {
  API_CHECKLIST_THINGS_TO_DO,
  API_CHECKLIST_STATUS_UPDATE,
} from "@/constants/APIUrls";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "Checklist",
  components: {
    HotelInfoModal: () => import("../HotelInfoModal/HotelInfoModal"),
    IntroEmailModal: () => import("../IntroEmailModal/IntroEmailModal.vue"),
  },
  data() {
    return {
      loading: false,
      infoEmailID: "",
      introFlag: false,
      // selectedBtn: true,
      selectedBtn: "All",
      selectedBtnList: [
        {
          name: "All",
          value: "All",
        },
        {
          name: "Pending",
          value: "Pending",
        },
        {
          name: "Completed",
          value: "Completed",
        },
        {
          name: "Overdue",
          value: "Overdue",
        },
      ],
      items: [],
      filter: "",
      header: [
        {
          width: "2%",
          sortable: false,
          text: "",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Task detail",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Due-Date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Completion-Date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Intro Mail Date",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        {
          sortable: false,
          text: "Action",
          align: "center",
          class: ["tableHeader-text", "tableHeader-bg"],
        },
        //  {
        ///    sortable: false,
        //   text: "Share",
        //   align: "center",
        //   class: ["tableHeader-text", "tableHeader-bg"],
        // },
      ],
    };
  },
  computed: {
    ...mapGetters({
      selectGameData: "gameManagement/getGame",
    }),
  },
  watch: {
    selectedBtn(value) {
      if (value === "All") {
        this.filter = "";
      } else {
        this.filter = value;
      }

      this.getChecklistData();
    },
  },
  created() {
    this.$root.$refs.checkList = this;
  },
  methods: {
    ...mapActions({
      toggleSponsorModal: "gameManagement/toggleSponsor",
      toggleHotelInfoModal: "gameManagement/toggleHotelInfoModal",
      toggleTicketInfoModal: "gameManagement/toggleTicketInfo",
      toggleAssemblyModal: "gameManagement/toggleAssembly",
      toggleVolunteerModal: "gameManagement/toggleVolunteer",
      toggleHomeTeamModal: "gameManagement/toggleHomeTeam",
      toggleAddEditViewGameModal: "gameManagement/toggleModal",
      toggleIntroEmailModal: "gameManagement/toggleIntroEmailModal",
    }),
    getChecklistData() {
      this.loading = true;
      const successHandler = (res) => {
        this.items = res.data.check_list;
        if (this.items.length != 0) {
          this.infoEmailID = this.items[0].id;
          if (this.items[0].action_dates.length >= 3) {
            this.introFlag = false;
          } else {
            this.introFlag = true;
          }
        }
        this.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        this.loading = false;
      };
      let formData = {};
      formData["game_id"] = this.$route.query.game_id;
      if (this.filter) {
        formData["filter"] = this.filter;
      }
      Axios.request_GET(
        API_CHECKLIST_THINGS_TO_DO,
        formData,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    updateStatus(status, id) {
      const successHandler = (res) => {
        console.log(res);
      };
      const failureHandler = (res) => {
        console.log(res);
      };
      const finallyHandler = () => {
        this.getChecklistData();
      };
      let formData = {};
      formData["game"] = this.$route.query.game_id;
      formData["id"] = id;
      formData["is_completed"] = status;
      Axios.request_PATCH(
        API_CHECKLIST_STATUS_UPDATE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        finallyHandler
      );
    },
    takeAction(key) {
      console.log(key);
      // this.toggleHotelInfoModal({show:true,type:"add"})
      switch (key) {
        case "first_call":
          this.toggleAddEditViewGameModal({
            show: true,
            type: "edit",
            dashboard: true,
            id: this.$route.query.game_id,
          });
          console.log(
            "checklistfirstcall",
            this.toggleAddEditViewGameModal({
              show: true,
              type: "edit",
              dashboard: true,
              id: this.$route.query.game_id,
            })
          );
          break;
        case "update_ticket_info":
          this.toggleTicketInfoModal({ show: true });
          break;
        case "add_sponsor":
          this.toggleSponsorModal({ show: true, type: "add" });
          break;
        case "add_volunteer":
          this.toggleVolunteerModal({ show: true, type: "add" });
          break;
        case "hotel_update":
          this.toggleHotelInfoModal({ show: true, type: "add" });
          break;
        case "add_players":
          this.toggleHomeTeamModal({ show: true, type: "add" });
          break;
        case "add_assemble":
          this.toggleAssemblyModal({ show: true, type: "add" });
          break;
        case "intro_email":
          this.toggleIntroEmailModal({
            show: true,
            type: "add",
            gameID: this.$route.query.game_id,
            checklistID: this.infoEmailID,
          });
          break;
        case "update_plan":
          break;
        default:
          break;
      }
    },
    routeGame() {
      this.$router.push({
        name: ROUTER_URL.gamePanel.children.gameallassembly.name,
      });
    },
    visitCalendarPanelHandler(game) {
      const { id } = game;
      console.log(id);
      let routerData = this.$router.resolve({
        name: ROUTER_URL.gamePanel.children.gamecalendar.name,
        query: { game_id: id },
      });
      window.open(routerData.href, "_blank");
    },
  },
  mounted() {
    this.getChecklistData();
  },
};
</script>
<style scoped>
.ticketTextHeading {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 140%;
  /* identical to box height, or 45px */

  color: #2c1963;
}
.ticketTextLine {
  width: 59px;
  height: 5px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 10px;
  background: #2c1963;
  border-radius: 23px;
}
.v-data-table.elevation-8.v-data-table--fixed-header.v-data-table--has-top.theme--light {
  border-radius: 6px;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.OVERDUE {
  color: #d21140;
  background: rgba(210, 17, 64, 0.12);
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  font-weight: 700;
  color: #067605;
  background: rgba(6, 118, 5, 0.12);
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.PENDING {
  font-size: 700;
}
.activeBtnClass.text-capitalize.v-btn {
  background: #ffba00;
  color: #2c1963;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 0px;
}
.notActiveBtnClass.text-capitalize.v-btn {
  color: #757575;
  background: #ececec;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 700;
  font-size: 17px;
  letter-spacing: 0px;
}
</style>
<style>
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 17px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
span.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  height: 45px;
  width: 38px;
  cursor: pointer;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
</style>
